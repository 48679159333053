import React, { useEffect, useState } from "react";
import "./Products.css";
import productList from "./products.json";
import { PageTitle } from "../../Utils";
import { Fade } from "react-reveal";
import { useFormik } from "formik";
import { Chip } from "@mui/material";

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const formik = useFormik({
    initialValues: {
      searchBy: "brand_name",
      searchText: "",
    },
  });

  useEffect(() => {
    setProducts(productList);
  }, []);

  useEffect(() => {
    setProducts(
      productList.filter((ele) =>
        ele[formik.values.searchBy]
          .toLowerCase()
          .includes(formik.values.searchText.toLowerCase())
      )
    );
  }, [formik.values.searchText]);

  return (
    <div className="productsContainer pt-5">
      <Fade bottom>
        <PageTitle title="Products" />
      </Fade>
      <Fade bottom>
        <div className="container pdtsTable">
          {/* <div className="srchCntnr">
            <div className="searchContainer">
              <div className="radOptions">
                <div className="radCntnr">
                  <input
                    id="brand_name"
                    type="radio"
                    value="brand_name"
                    name="searchBy"
                    onChange={formik.handleChange}
                    checked={formik.values.searchBy === "brand_name"}
                  />
                  <label
                    className={`${
                      formik.values.searchBy === "brand_name" ? "active" : ""
                    }`}
                    for="brand_name"
                  >
                    Brand Name
                  </label>
                </div>
                <div className="radCntnr">
                  <input
                    id="therapeutic_area"
                    type="radio"
                    value="therapeutic_area"
                    name="searchBy"
                    onChange={formik.handleChange}
                    checked={formik.values.searchBy === "therapeutic_area"}
                  />
                  <label
                    className={`${
                      formik.values.searchBy === "therapeutic_area"
                        ? "active"
                        : ""
                    }`}
                    for="therapeutic_area"
                  >
                    Therapeutic Area
                  </label>
                </div>
              </div>
              <input
                placeholder="Search"
                name="searchText"
                value={formik.values.searchText}
                onChange={formik.handleChange}
              />
            </div>
          </div> */}
          <div className="tableCntnr">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="slNo">Sl. No</th>
                  <th className="brndName">Brand Name</th>
                  <th>Composition</th>
                  <th>Therapeutic Area</th>
                </tr>
              </thead>
              <tbody>
                {products.map((data, idx) => (
                  <tr>
                    <td>{idx + 1}</td>
                    <td>
                      {data.brand_name}{" "}
                      {/* <Chip
                        size="small"
                        className={`chipCls ${data.type}`}
                        label={
                          data.type === "own" ? "Manufactured" : "Imported"
                        }
                      /> */}
                    </td>
                    <td>{data.composition}</td>
                    <td>{data.therapeutic_area}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default ProductsPage;
