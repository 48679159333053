import React from "react";
import "./Home.css";
import CountUp from "react-countup";
import Fade from "react-reveal/Fade";
import { CustomTitle } from "../../Utils";
import TopBrands from "./TopBrands";
import ProductsSlide from "./ProductsSlide";
import CategorySlides from "./CategorySlides";
import ContactForm from "../../Components/ContactForm";

const Homepage = () => {
  return (
    <div className="homeWrapper">
      {/* <Fade bottom>
        <div className="heroContainer">
          <div className="vdoWrapper">
            <video loop autoPlay className="vdoCntnr" muted>
              <source src="/assets/video/banner.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="heroTxt">
            <div className="container">
              <h2>
                Affordable medicines for all , improving health and enhancing
                patient experience and reducing per capita cost of medicine for
                the benefit of the community
              </h2>
            </div>
          </div>
        </div>
      </Fade> */}
      {/* <Fade bottom>
        <div className="container">
          <div className="statContainer">
            <div className="statCard">
              <img src="/assets/svg/product_icon.svg" />
              <div className="statTxt">
                <h4>
                  <CountUp enableScrollSpy end={5} duration={2} />+
                </h4>
                <p>Products</p>
              </div>
            </div>
            <div className="statCard">
              <img src="/assets/svg/location_icon.svg" />
              <div className="statTxt">
                <h4>
                  <CountUp enableScrollSpy end={10} duration={2} />+
                </h4>
                <p>Locations</p>
              </div>
            </div>
            <div className="statCard">
              <img src="/assets/svg/office_icon.svg" />
              <div className="statTxt">
                <h4>
                  <CountUp enableScrollSpy end={20} duration={2} />+
                </h4>
                <p>Associate Offices</p>
              </div>
            </div>
            <div className="statCard">
              <img src="/assets/svg/workforce_icon.svg" />
              <div className="statTxt">
                <h4>
                  <CountUp enableScrollSpy end={200} duration={2} />+
                </h4>
                <p>Workforce</p>
              </div>
            </div>
          </div>
        </div>
      </Fade> */}
      <Fade bottom>
        <div className="container flxCntnr wlcmCntnr">
          <div className="row">
            <div className="col-md-6">
              <div className="imgContainer">
                <img className="crvImg" src="/assets/images/welcome_pic.png" />
                <div className="polCntnr">
                  <img src="/assets/svg/polygon.svg" />
                  <div className="polTxtCntnr">
                    <h5>25+</h5>
                    <p>YEARS OF EXPERTISE</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <section className="prTxtCntnr ps-4">
                <CustomTitle
                  title={
                    <>
                      OA<sup>&</sup>J
                    </>
                  }
                  supTitle="WELCOME TO"
                  size="large"
                />
                <section className="subPara">
                  <p>
                    A leading pharmaceutical company in the heart of Ghana
                    dedicated to promoting health and well-being for people of
                    all ages. Established with a vision in 2017, our mission
                    revolves around making quality medicines accessible and
                    affordable, ensuring that individuals can lead healthy and
                    happy lives.
                  </p>
                  <p>
                    At OA&J, we recognize the crucial role that pharmaceuticals
                    play in enhancing the quality of life, and we are committed
                    to contributing to the health of the Ghanaian community. Our
                    focus extends beyond mere business; it is rooted in a
                    genuine desire to positively impact lives through affordable
                    healthcare solutions.
                  </p>
                  <p>
                    We understand the importance of accessibility, and our
                    distribution network spans the nation. From urban centres to
                    remote areas, we strive to reach every corner of Ghana,
                    making our medicines readily available to all. Our goal is
                    not only to meet the healthcare needs of today but to
                    contribute to a healthier and happier future for generations
                    to come.
                  </p>
                </section>
              </section>
            </div>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="container">
          <div className="tpBrndsCntnr">
            <CustomTitle title="top brands" supTitle="our" />
            <div className="sldrDiv">
              <TopBrands />
            </div>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="prdtsCntnr">
          <div className="container">
            <CustomTitle title="products" supTitle="our" type="center" />
            <ProductsSlide />
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="prdtsCntnr thraSgmnts">
          <div className="container">
            <CustomTitle
              title="therapeutic segments"
              supTitle="our"
              type="center"
            />
            <CategorySlides />
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <ContactForm />
      </Fade>
    </div>
  );
};

export default Homepage;
