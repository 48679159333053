import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import ProductCard from "../../Components/ProductCard";

const ProductsSlide = () => {
  const products = [
    {
      image: "/assets/images/products/actilife.png",
      title: "Actilife Multivitamin Caps",
      description: "Multivitamin Caps",
    },
    {
      image: "/assets/images/products/zulu100.png",
      title: "Zulu 100",
      description: "Multivitamin Caps",
    },
    {
      image: "/assets/images/products/zuluplus.png",
      title: "Zulu Plus",
      description: "Multivitamin Caps",
    },
    {
      image: "/assets/images/products/bclar.png",
      title: "B-Clar 500",
      description: "Multivitamin Caps",
    },
  ];
  return (
    <div>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={15}
        slidesPerView={4}
        navigation
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        breakpoints={{
          100: {
            slidesPerView: 1,
          },
          700: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {products.map((data, idx) => (
          <SwiperSlide key={idx}>
            <ProductCard {...data} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductsSlide;
