import React, { useState, useCallback, useEffect } from "react";
import "../AboutUs/AboutUs.css";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { PageTitle } from "../../Utils";
import { Fade } from "react-reveal";

const PhotoGallery = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photos, setPhotos] = useState([]);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => {
    const photoArray = [...Array(14)].map((_, index) => {
      return {
        src: `/assets/images/gallery/image${index + 1}.jpeg`,
      };
    });
    setPhotos(photoArray);
  }, []);

  return (
    <div className="teamGallery">
      <div className="container">
        <Fade bottom>
          <PageTitle title="Our Gallery" />
        </Fade>
        <Fade bottom>
          <Gallery photos={photos} onClick={openLightbox} />
        </Fade>
      </div>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default PhotoGallery;
