import React, { useEffect } from "react";
import "./Home.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Intro = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.location.replace("/home");
    }, 18000);
  }, []);
  return (
    <div className="homeWrapper">
      <div className="heroContainer">
        <a href="/home">
          <Button className="skipBtn">
            Skip Intro{" "}
            <span class="material-symbols-outlined ms-1">skip_next</span>
          </Button>
        </a>
        <div className="vdoWrapper">
          <video loop autoPlay className="vdoCntnr" muted>
            <source src="/assets/video/banner.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="heroTxt">
          <div className="container">
            <img className="logoVw" src="/assets/images/oaj-logo-white.png" />
            <h2>
              Affordable medicines for all , improving health and enhancing
              patient experience
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
