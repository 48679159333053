import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./ContactForm.css";
import { CustomTitle } from "../../Utils";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";

const ContactForm = () => {
  const location = useLocation();
  const form = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      user_name: "",
      email: "",
      phone_number: "",
      message: "",
    },
  });

  const handleFormReset = () => {
    console.log("form.current");
  };

  console.log("form", form, form.current);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        {
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          toast.success("Message Sent Successfully!");
          formik.handleReset();
          setIsLoading(false);
        },
        (error) => {
          toast.warning(error.text);
          setIsLoading(false);
        }
      );
  };
  return (
    <div
      className={`cntctWrpr ${
        location.pathname === "/contact-us" ? "cntctPage" : ""
      }`}
    >
      <div className="container">
        <div className="contCntnr">
          <div className="cntctInfoWrpr">
            <CustomTitle supTitle="contact us" />
            <div className="cntctCard">
              <img src="/assets/svg/location.svg" />
              <div className="cntctVl adCntwr">
                <div>
                  <p className="adrsHdr">
                    <a
                      href="https://maps.app.goo.gl/8uaABcBpRrUJPVUN6"
                      target="_blank"
                    >
                      factory address
                      <span class="material-symbols-outlined ms-1">
                        open_in_new
                      </span>
                    </a>
                  </p>
                  <p>
                    <span>OA&J Pharmaceuticals Ltd</span>
                    Plot No IND/A/71/G/11 Tema Heavy Industrial Area Tema, GHANA
                  </p>
                </div>
                {/* <div>
                  <p className="adrsHdr">
                    <a href="" target="_blank">
                      corporate office
                      <span class="material-symbols-outlined ms-1">
                        open_in_new
                      </span>
                    </a>
                  </p>
                  <p>
                    <span>OA&J Pharmaceuticals Ltd</span>
                    #7, Oleander Stret, Big Apple Junction, East Legon Accra -
                    Ghana.
                  </p>
                </div> */}
                <div>
                  <p className="adrsHdr">
                    <a
                      href="https://maps.app.goo.gl/T4k3mwDL9zBeSKit5"
                      target="_blank"
                    >
                      admin, sales, distribution - Accra
                      <span class="material-symbols-outlined ms-1">
                        open_in_new
                      </span>
                    </a>
                  </p>
                  <p>
                    <span>OA&J Pharmaceuticals Ltd</span>
                    #16, Bill Street, POBOX 8940, East Legon, Accra - Ghana
                  </p>
                </div>
                <div>
                  <p className="adrsHdr">
                    <a
                      href="https://www.ghanapostgps.com/map/#AK4891682"
                      target="_blank"
                    >
                      admin, sales, distribution - Kumasi
                      <span class="material-symbols-outlined ms-1">
                        open_in_new
                      </span>
                    </a>
                  </p>
                  <p>
                    <span>OA&J Pharmaceuticals Ltd</span>
                    #7, 17th Street Sector 3 Atasomanso POBOX 8940 Kumasi -
                    Ghana
                  </p>
                </div>
                <div>
                  <p className="adrsHdr">
                    <a
                      href="https://www.ghanapostgps.com/map/#AK4891682"
                      target="_blank"
                    >
                      Tamale Branch
                      <span class="material-symbols-outlined ms-1">
                        open_in_new
                      </span>
                    </a>
                  </p>
                  <p>
                    <span>OA&J Pharmaceuticals Ltd</span>
                    A-27 KALPHOIN ESTATE, 3rd street Opposite Football ground,
                    POBOX 8940 Tamale – Ghana
                  </p>
                </div>
              </div>
            </div>
            <div className="cntctCard">
              <img src="/assets/svg/phone.svg" />
              <div className="cntctVl adCntwr cntctNmbr">
                <p>
                  <a>(+233 / +0) 30 250 2098</a>
                  <br />
                  <a>(+233 / +0) 56 000 4444</a>
                  <br />
                  <a>(+233 / +0) 30 252 5001</a>
                </p>
                {/* <p>
                  <a href="tel:0302502098">030 250 2098</a>
                  <br />
                  <a href="tel:0560004444">056 000 4444</a>
                  <br />
                  <a href="tel:0302525001">030 252 5001</a>
                </p> */}
              </div>
            </div>
            <div className="cntctCard">
              <img src="/assets/svg/mail.svg" />
              <div className="cntctVl">
                <p>
                  <a href="mailto:oajpharma@gmail.com">oajpharma@gmail.com</a>
                </p>
              </div>
            </div>
            {/* <div className="sclMdaInfo">
              <a
                href="https://www.facebook.com/OAJ-Pharmaceuticals-101053728257509"
                target="_blank"
              >
                <img src="/assets/svg/facebook.svg" />
              </a>
              <a
                href="https://www.linkedin.com/company/oajpharma-ghana/"
                target="_blank"
              >
                <img src="/assets/svg/linkedin.svg" />
              </a>
              <a
                href="https://www.instagram.com/oajpharma_ghana/"
                target="_blank"
              >
                <img src="/assets/svg/instagram.svg" />
              </a>
              <a href="https://twitter.com/OAJPharma_Ghana" target="_blank">
                <img src="/assets/svg/twitter.svg" />
              </a>
            </div> */}
          </div>
          <div className="cntctFrmCntnr">
            <form ref={form} onSubmit={sendEmail}>
              <div className="row">
                <div className="col-md-12">
                  <CustomInput
                    label="Name"
                    placeholder="Enter name"
                    name="user_name"
                    upperLabel
                    onChange={formik.handleChange}
                    value={formik.values.user_name}
                  />
                </div>
                <div className="col-md-12">
                  <CustomInput
                    label="Email"
                    placeholder="Enter email address"
                    name="email"
                    upperLabel
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </div>
                <div className="col-md-12">
                  <CustomInput
                    label="Phone Number"
                    placeholder="Enter phone number"
                    name="phone_number"
                    upperLabel
                    onChange={formik.handleChange}
                    value={formik.values.phone_number}
                  />
                </div>
                <div className="col-md-12">
                  <CustomInput
                    label="Message"
                    placeholder="Write your message here..."
                    name="message"
                    multiline
                    upperLabel
                    onChange={formik.handleChange}
                    value={formik.values.message}
                  />
                </div>
                <div className="col-md-6">
                  <input
                    disabled={isLoading}
                    type="submit"
                    value="Send Message"
                    className="sendButton"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
