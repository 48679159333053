import React from "react";
import "./ProductCard.css";

const ProductCard = ({ image, title, description }) => {
  return (
    <div className="prdtCard">
      <div className="pdtImgCntnr">
        <img src={image} />
      </div>
      <div className="pdtDtls">
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ProductCard;
