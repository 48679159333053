import React from "react";
import "../AboutUs/AboutUs.css";
import Fade from "react-reveal/Fade";
import { CustomTitle, PageTitle } from "../../Utils";

const Management = () => {
  return (
    <div className="mgmtContainer">
      <Fade bottom>
        <div className="sctnHroCntr">
          <img
            className="mgmtBnrImg"
            src="/assets/images/managementbanner.png"
          />
          <div className="container">
            <div className="infoContainer">
              <div className="row">
                <div className="col-md-6">
                  <div className="txtCntnr">
                    <PageTitle title="management" className="text-start" />
                    <CustomTitle
                      title="FOUNDERS PAGE OF OA&J"
                      supTitle="Welcome to"
                    />
                    <p>
                      Where the visionaries behind our pharmaceutical venture
                      come to life. Meet the driving force, the architects of
                      our commitment to healthcare excellence and innovation.
                    </p>
                    <p>
                      Mr. Olaganathan - MD and Mr. Jagdish Maheshwari, the
                      brilliant minds who laid the foundation of OA&J
                      Pharmaceuticals Ltd, brought together their expertise,
                      passion, and dedication to establish a pharmaceutical
                      company that goes beyond the ordinary. With a shared
                      vision of making a meaningful impact on global health,
                      they set out on a journey in 2012 to redefine the
                      standards of pharmaceutical excellence.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="container txtCntnr">
          <p className="mgmtTxt text-center">
            Our aim is to deliver world-class pharmaceutical products at very
            affordable prices. We want OA & J pharmaceutical based in Ghana and
            serving all the sub-Sahara west African countries and to be emerged
            as one of the top-rated pharmaceutical companies with an excellent R
            & D background supported some of our key products which has
            undergone clinicals and bioequivalence. OA & J Pharmaceutical
            Limited is an indigenous company and its product line includes a
            broad portfolio of innovative prescription products in important
            therapeutic segments. The founder, Mr. Olaganathan Arumugam & Mr.
            Jagdish Maheshwari both have more than three-decade pharma
            experience both in manufacturing and marketing and developing and
            creating brands in Ghana & Abroad.
          </p>
        </div>
      </Fade>
      <Fade bottom>
        <div className="container">
          <div className="founderCard txtCntnr">
            <div className="fndrImgWrpr">
              <img src="/assets/images/founder1.png" />
            </div>
            <p>
              Mr. Olaganathan Arumugam, - MD, having dedicated more than 30
              years to the field of pharmaceuticals, his passion for improving
              lives through innovative and accessible healthcare solutions has
              been the driving force behind the inception of OA&J Pharma. His
              mission is simple yet profound: to contribute meaningfully to the
              well-being of individuals around the globe. His team of dedicated
              experts, researchers, and professionals strives tirelessly to push
              the boundaries of scientific discovery, ensuring that our products
              meet the highest standards of safety, efficacy, and quality. His
              commitment extends beyond the development of groundbreaking
              medications. He is equally devoted to fostering a culture of
              transparency, integrity, and social responsibility. Through
              partnerships and collaborations, he aim to address healthcare
              challenges on a global scale, working towards a healthier, more
              sustainable future for all.
            </p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="container">
          <div className="founderCard coFndrCard txtCntnr">
            <p>
              Mr. Jagdish Maheshwari is a first-generation entrepreneur and
              visionary. He is a pharmacist by profession, and he has served 20
              years both in domestic and international marketing field and
              worked with top rated pharma companies. He also has 10 years’
              experience in pharma manufacturing before laying the foundation of
              OA & J in Ghana. Under his leadership OA & J has become a
              well-respected and reputable company in Ghana And emerging as one
              of the fastest raising pharma company in Ghana.
            </p>
            <div className="fndrImgWrpr">
              <img src="/assets/images/founder2.png" />
            </div>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="container txtCntnr">
          <p className="mgmtTxt text-center">
            Together, Mr. Olaganathan Arumugam and Mr. Jagadish Maheshwari lead
            OA&J Pharmaceuticals Ltd, with a collective mission to provide
            access to affordable, high-quality medicines globally. Their
            unwavering dedication to advancing healthcare and improving lives
            forms the cornerstone of OA&J Pharmaceutical's success.
          </p>
        </div>
      </Fade>
    </div>
  );
};

export default Management;
