import React from "react";
import "./Header.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Button, useMediaQuery } from "@mui/material";

const Header = () => {
  const location = useLocation();
  const [state, setState] = React.useState(false);
  const mobile = useMediaQuery("(max-width: 700px)");

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const NavMenu = () => {
    return (
      <ul className="rtNavList">
        <li>
          <NavLink to="/home">Home</NavLink>
        </li>
        <li>
          <NavLink to="/about">About Us</NavLink>
        </li>
        <li>
          <NavLink to="/management">Management</NavLink>
        </li>
        <li>
          <NavLink to="/products">Products</NavLink>
        </li>
        <li>
          <NavLink to="/collaboration">Collaboration</NavLink>
        </li>
        <li>
          <NavLink to="/gallery">Gallery</NavLink>
        </li>
        <li>
          <NavLink to="/careers">Careers</NavLink>
        </li>
        <li>
          <NavLink to="/contact-us">Contact Us</NavLink>
        </li>
      </ul>
    );
  };

  return (
    // <div className={`header ${location.pathname === "/" && "homeHeader"}`}>
    <div className={`header `}>
      <div className="container flxCntnr justify-content-between">
        <div className="logoContainer">
          <Link to="/home">
            {/* <img
              src={
                location.pathname === "/"
                  ? "/assets/images/oaj-logo-white.png"
                  : "/assets/images/oaj-logo.png"
              }
            /> */}
            <img src={"/assets/images/oaj-logo.png"} />
          </Link>
        </div>

        {mobile ? (
          <Button className="menuBtn" onClick={toggleDrawer(true)}>
            <span class="material-symbols-outlined">menu</span>
          </Button>
        ) : (
          <NavMenu />
        )}
      </div>
      {mobile && (
        <SwipeableDrawer
          anchor={"left"}
          open={state}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box
            className="mobileNav"
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <NavMenu />
          </Box>
        </SwipeableDrawer>
      )}
    </div>
  );
};

export default Header;
