import "./App.css";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Router from "./Router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#021613",
      },
      // secondary: {
      //   main: "#021613",
      // },
    },
    typography: {
      fontFamily: "var(--primFont)",
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Router />
      </ThemeProvider>
    </div>
  );
}

export default App;
