export const CustomTitle = ({ title, supTitle, size, type }) => {
  return (
    <div className={`ttleTxtCntnr ${type}`}>
      <p className="supTitle">{supTitle}</p>
      <h2 className={`${size}`}>{title}</h2>
    </div>
  );
};

export const PageTitle = ({ title, className }) => {
  return <h4 className={`pageTitle ${className}`}>{title}</h4>;
};
