import React from "react";
import ContactForm from "../../Components/ContactForm";

const ContactUs = () => {
  return (
    <>
      <ContactForm />
    </>
  );
};

export default ContactUs;
