import React from "react";
import "./CategoryCard.css";

const CategoryCard = ({ image, title }) => {
  return (
    <div className="catCard">
      <div className="catImgCntnr">
        <img src={image} />
      </div>
      <p>{title}</p>
    </div>
  );
};

export default CategoryCard;
