import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import ProductCard from "../../Components/ProductCard";
import CategoryCard from "../../Components/CategoryCard";

const CategorySlides = () => {
  const products = [
    { title: "Antibiotic", image: "/assets/svg/category/antibiotics.svg" },
    {
      image: "/assets/svg/category/cardiology.svg",
      title: "Cardiology",
    },
    {
      image: "/assets/svg/category/endo.svg",
      title: "Endocrionology",
    },
    {
      image: "/assets/svg/category/gastrology.svg",
      title: "Gastrology",
    },
    {
      image: "/assets/svg/category/hematalogy.svg",
      title: "Hematology",
    },
    {
      image: "/assets/svg/category/neurology.svg",
      title: "Neurology",
    },
    {
      title: "Vitamin + Zinc Supplement",
      image: "/assets/svg/category/vitamin.svg",
    },
    { title: "Dietry Sulpplement", image: "/assets/svg/category/diet.svg" },
    {
      title: "Calcium Supplement",
      image: "/assets/svg/category/calcium.svg",
    },
    { title: "Anti-Coagulant", image: "/assets/svg/category/bloodcells.svg" },
    {
      title: "Anti-Inflammatary",
      image: "/assets/svg/category/antiinflam.svg",
    },
    // { title: "Cephalosporin Antibiotics" },
    // { title: "Fluoroquinolones Antibotics" },
    // { title: "To treat diarrhea and dysentry " },
    // { title: "Anti-Hyperlipedemic agent" },

    // { title: "Broad-spectrum carbapenem antibiotic." },
    // { title: "Vitamin Supplement" },
    // { title: "Iron, Folic & Vitamin Supplement" },
    // { title: "Analgesic, Anti-Inflammatory & CNS Stimulant" },
    // { title: "Analgesic, Anti-Inflammatory & Muscle Relaxant" },
    // { title: "Analgesic & Anti-Inflammatory " },
    // { title: "Macrolide Antibiotica" },
    // {
    //   title:
    //     "Management and treatment of erectile dysfunction and pulmonary arterial hypertension. ",
    // },
    // { title: "Management and treatment of erectile dysfunction and BPH" },
    // { title: "Anti- Epilepsy & Anti-Anxiety" },
    // { title: "Anti-Diarrhea" },
    // { title: "Analgesic, Antipyretic relive the symtomps of cold & flu" },
    // { title: "For Bacterial & Parasitic Infection" },
    // { title: "Anti-Malarial " },
    // { title: "Tetracycline Antibiotic" },
    // { title: "Antihypertensive agent" },
    // { title: "Antihypertensive agent with diuretics" },
    // { title: "Anti-Diabetic agent" },
    // { title: "Iron Deficiency" },
    // { title: "Anthelmintic" },
    // { title: "Antibiotics" },
    // { title: "Antiemetic  and prokinetic drug" },
    // { title: "Antifungal Agent" },
    // { title: "For Zinc Deficiency" },
    // { title: "Folic Acid Deficiency" },
    // { title: "Diuretics" },
    // { title: "Anti-Gout agent" },
    // { title: "Bronchodilators" },
    // { title: "Anti-Hypertensive agent" },
    // { title: "Antifibrinolytics" },
    // { title: "For Seasonal Allergy & cold" },
    // { title: "For Urinary Tract Infection" },
    // { title: "Muscle Relaxant" },
    // { title: "Analgesic & Antipyretic agent" },
    // { title: "For Agina & BP" },
    // { title: "Anti-Depressant" },
    // { title: "To treat gastric and duodenal ulcers" },
    // { title: "Anti-Hyperlipedemic Agent" },
    // { title: "Anti-Inflammatory " },
  ];

  // const products = [
  //   {
  //     image: "/assets/svg/category/cardiology.svg",
  //     title: "Cardiology",
  //   },
  //   {
  //     image: "/assets/svg/category/endo.svg",
  //     title: "Endocrionology",
  //   },
  //   {
  //     image: "/assets/svg/category/gastrology.svg",
  //     title: "Gastrology",
  //   },
  //   {
  //     image: "/assets/svg/category/hematalogy.svg",
  //     title: "Hematology",
  //   },
  //   {
  //     image: "/assets/svg/category/neurology.svg",
  //     title: "Neurology",
  //   },
  // ];
  return (
    <div>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={15}
        slidesPerView={4}
        navigation
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        breakpoints={{
          100: {
            slidesPerView: 1,
          },
          700: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 5,
          },
        }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {products.map((data, idx) => (
          <SwiperSlide key={idx}>
            <CategoryCard {...data} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CategorySlides;
