import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

const TopBrands = () => {
  const brandLogo = [
    "/assets/images/brands/zulu-100.jpeg",
    "/assets/images/brands/zulumr.jpeg",
    "/assets/images/brands/zuluplus.jpeg",
    "/assets/images/brands/zulupro.jpeg",
    "/assets/images/brands/innomero.jpeg",
    "/assets/images/brands/frelet.jpeg",
    "/assets/images/brands/actilife.jpeg",
    "/assets/images/brands/foxitil.jpeg",
    "/assets/images/brands/ferum.jpg",
    "/assets/images/brands/nutrimom.jpeg",
  ];
  return (
    <div>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={15}
        slidesPerView={4}
        navigation
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        breakpoints={{
          100: {
            slidesPerView: 1,
          },
          700: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {brandLogo.map((data, idx) => (
          <SwiperSlide key={idx}>
            <div className="brndImg">
              <img src={data} style={{ width: "100%" }} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default TopBrands;
