import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "../Components/Header";
import Homepage from "../Views/Homepage";
import Footer from "../Components/Footer";
import AboutUs from "../Views/AboutUs";
import Management from "../Views/Management";
import Collaboration from "../Views/Collaboration";
import Careers from "../Views/Careers";
import ContactUs from "../Views/ContactUs";
import PhotoGallery from "../Views/Gallery";
import ProductsPage from "../Views/Products";
import Intro from "../Views/Homepage/intro";

const Router = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location]);

  return (
    <>
      <BrowserRouter>
        {window.location.pathname != "/" && <Header />}
        <Routes>
          <Route path="/" element={<Intro />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/management" element={<Management />} />
          <Route path="/collaboration" element={<Collaboration />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/gallery" element={<PhotoGallery />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
        {window.location.pathname != "/" && <Footer />}
      </BrowserRouter>
    </>
  );
};

export default Router;
