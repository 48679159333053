import React from "react";
import { CustomTitle, PageTitle } from "../../Utils";
import { Fade } from "react-reveal";

const Careers = () => {
  return (
    <div className="careerContainer">
      <Fade bottom>
        <div className="sctnHroCntr">
          <PageTitle title="CAREERS" />
          <div className="container">
            <div className="infoContainer">
              <div className="row">
                <div className="col-md-6">
                  <div className="txtCntnr">
                    <CustomTitle
                      title="CAREER PAGE OF OA&J"
                      supTitle="Welcome to"
                    />
                    <p>
                      Where opportunity meets passion, and individuals with a
                      drive for excellence come together to shape the future of
                      healthcare. As a dynamic and innovative pharmaceutical
                      company, we believe that our greatest asset is our people.
                    </p>
                    <p>
                      If you are keen to be associated with our organization and
                      feel that you have the skills to be a independent self
                      starter who is ambitious and have what it takes, we would
                      like to hear from you.
                    </p>
                    <div className="careerCntct">
                      <p className="crtTl">
                        Please contact / drop in your details at:
                      </p>
                      <img className="usrImg" src="./assets/images/manohar.jpeg" />
                      <p className="nmInfo">
                        <span>Manohar Marda</span> <br />
                        Director - Operations & Sales <br />
                      </p>
                      <ul className="cntctInfo">
                        <li>
                          <span className="iconWrp">
                            <img src="/assets/svg/phone.svg" />
                          </span>
                          <a href="tel:+233540677629">+233 54 067 7629</a>
                        </li>
                        <li>
                          <span className="iconWrp">
                            <img src="/assets/svg/mail.svg" />
                          </span>
                          <div>
                            <a href="mailto:manohar@oajpharma.com">
                              manohar@oajpharma.com
                            </a>
                            {/* <br />
                            <a href="mailto:oajpharma@gmail.com">
                              oajpharma@gmail.com
                            </a> */}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="imgCntnr">
                    <img src="/assets/images/careerimage.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Careers;
