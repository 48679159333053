import React from "react";
import Fade from "react-reveal/Fade";
import { PageTitle } from "../../Utils";

const Collaboration = () => {
  return (
    <div className="clbrtnCntnr">
      <Fade bottom>
        <PageTitle title="COLLABORATION & PARTNERSHIP" />
      </Fade>
      <Fade bottom>
        <div className="clbrtnImgWrpr">
          <h3>Enhancing Innovation & Market Access</h3>
        </div>
      </Fade>
      <Fade bottom>
        <div className="othrInfoCntnr">
          <div className="container txtCntnr">
            <p>
              Welcome to the Partners Page of OA&J, a space dedicated to
              celebrating the collaborative spirit that propels our mission
              forward. At OA&J, we believe that meaningful partnerships are the
              cornerstone of advancing healthcare globally
            </p>
            <span className="brShd"></span>
            <p>
              Our commitment to excellence extends beyond our internal
              operations, reaching into strategic alliances and collaborations
              with esteemed partners. The Partners Page serves as a testament to
              the valuable relationships we have cultivated across the
              pharmaceutical and healthcare ecosystem.
            </p>
            <span className="brShd"></span>
            <p>
              Whether it's research collaborations, distribution partnerships,
              or innovative ventures, each connection plays a pivotal role in
              driving our shared goal of enhancing healthcare accessibility and
              outcomes.
            </p>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="clbrtnTxtCntnr txtCntnr container">
          <div className="row">
            <div className="col-md-6">
              <h4>ATOZ PHARMACEUTICALS LTD – CHENNAI INDIA</h4>
              <h6>Technical Collaboration</h6>
              <p>
                The pharmaceutical industry is a significant contributor to the
                advancements in healthcare, improving its outcomes and enhancing
                the quality of life of people worldwide. As a result of rapidly
                growing technology, cut-throat competition, and a complicated
                regulatory environment, collaborations and partnerships have
                emerged as crucial strategies for pharmaceutical companies to
                achieve growth, stimulate innovation, and expand their market
                access.
              </p>
            </div>
            <div className="col-md-6">
              <h4>AZIDUS INDIA & AZIDUS GHANA</h4>
              <h6>Outsourcing R&D Processes from CROs</h6>
              <p>
                Contract Research Organizations (CROs) are entities within the
                service sector that provide various services to pharmaceutical
                and biotechnology companies. Instead of recruiting full-time
                staff with the specialized expertise required for performing
                specific tasks within the development process, pharmaceutical
                companies hire a CRO to conduct these tasks externally. Research
                services include activities ranging from the identification of
                new drugs to bringing them to market, with a specific emphasis
                on the clinical phase. AZIDUS is one of the leading CROs in the
                world.
              </p>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Collaboration;
