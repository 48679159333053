import React from "react";
import "./AboutUs.css";
import Fade from "react-reveal/Fade";
import { CustomTitle, PageTitle } from "../../Utils";

const AboutUs = () => {
  return (
    <div className="aboutContainer">
      <Fade bottom>
        <div className="sctnHroCntr">
          <PageTitle title="about us" />
          <div className="container">
            <div className="infoContainer">
              <div className="row">
                <div className="col-md-6">
                  <div className="txtCntnr">
                    <CustomTitle
                      title={
                        <>
                          OA<sup>&</sup>J PHARMACEUTICALS LTD,
                        </>
                      }
                      supTitle="Welcome to THE ONLINE PRESENCE OF "
                    />
                    <p>
                      A pioneering pharmaceutical company based in Ghana. Since
                      our establishment in 2017, we have emerged as a trusted
                      name in the industry, dedicated to manufacturing
                      high-quality tablets, capsules, and liquids. Our
                      commitment revolves around providing accessible and
                      top-notch medicines, not only in Ghana but also throughout
                      the West African sub-region.
                    </p>
                    <p>
                      At OA&J Pharmaceuticals, we take pride in our extensive
                      client network that spans government and private
                      hospitals, wholesalers, and pharmacies. With a steadfast
                      focus on quality and affordability, we have successfully
                      penetrated major institutions and key government hospitals
                      in the country. Our commendable track record includes
                      continuous and reliable medicine supplies under a
                      framework contract with the Ministry of Health (MOH)
                      Ghana.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="imgCntnr">
                    <img src="/assets/images/aboutimage1.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="sctnHroCntr scndHro">
          <div className="container">
            <div className="infoContainer">
              <div className="row">
                <div className="col-md-6">
                  <div className="imgCntnr">
                    <img src="/assets/images/aboutimage2.png" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="txtCntnr">
                    <p>
                      Navigating our website, you'll discover the strength of
                      our distribution and networking system. We operate four
                      strategically located distribution outlets and warehouses
                      in Accra, Kumasi, Tamale, and Takoradi. Each facility is
                      equipped with state-of-the-art warehousing systems,
                      distribution vans, and a proficient supply chain team to
                      ensure seamless operations.
                    </p>
                    <p>
                      Our direct supply chain extends to government and private
                      institutions, pharmacies, and wholesalers, reaching
                      approximately 500 hospitals and 2000 pharmacies and
                      wholesalers across Ghana. Our local manufacturing
                      endeavours have gained widespread acceptance, with our
                      medicines being extensively utilized by healthcare
                      providers nationwide.
                    </p>
                    <p>
                      As you explore our website, you'll gain insights into our
                      dedicated team of 100 professionals, including junior and
                      senior management across supply chain, marketing, and
                      administration. Additionally, approximately 75-100
                      personnel contribute to our manufacturing operations,
                      reflecting our commitment to excellence at every stage of
                      the pharmaceutical process.
                    </p>
                    <p className="quote">
                      “Thank you for visiting OA&J Pharmaceuticals Ltd online.
                      We invite you to delve deeper into our offerings, values,
                      and contributions to healthcare in Ghana and beyond.”
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="container">
          <div className="msnVsnWrpr">
            <CustomTitle
              title="vision & mission"
              supTitle="our"
              type="center"
            />
            <div className="msnVsnGrid">
              <div className="msnVsnCard msnCard txtCntnr">
                <h3>MISSION</h3>
                <h5>
                  <span>"A promise of Health”</span>  as it says on our logo is
                  what we are all about.
                </h5>
                <p>
                  A holistic view of health in its most broad sense encompassing
                  the health of the company, the people working with us, our
                  colleagues who strive to make us better, the health of our
                  associates and people who do business with us and beyond and
                  in its most narrow sense of actually providing quality
                  affordable medicine to the people who need it most, we intend
                  to live upto our promise of affordable health to everyone.
                </p>
              </div>
              <div className="msnVsnCard vsnCard txtCntnr">
                <h3>VISION</h3>
                <p>
                  We aim to be the leading manufacturer of pharmaceuticals for
                  regular and innovative dosage forms in Ghana with exports to
                  whole of Africa as a stepping stone to expanding our global
                  footprint.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <Fade bottom>
        <div className="container">
          <div className="msnVsnGrid">
            <div className="txtCntnr">
              <CustomTitle title="Values & Responsibility" supTitle="Our" />
              <p>
                <i>
                  " Individual & organization behavior at OA&J Pharmaceuticals
                  is defined by our values which are Empathy, Courage &
                  Integrity. We try to ensure that these core beliefs reflect in
                  our day to day operations and the people are sensitized around
                  these core beliefs "
                </i>
              </p>
            </div>
            <div className="vleImgCntnr">
              <img src="/assets/images/valuesimage.png" />
            </div>
          </div>
        </div>
      </Fade>
      {/* <Fade bottom>
        <div className="corVdoCntnr">
          <div className="container">
            <CustomTitle title="video" supTitle="corporate" type="center" />
            <div className="vdoCntnr">
              <video controls>
                <source
                  src="/assets/video/oaj-corporate-video.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </Fade> */}
      <Fade bottom>
        <div className="mapContnr">
          <div className="container txtCntnr">
            <CustomTitle title="in ghana" supTitle="operations" type="center" />
            <p className="text-center">
              We have offices and warehouses in Accra, Kumasi , Tamale &
              Takoradi and with a strong logistics and warehousing set-up in
              various parts of the country, our ability to supply medicines with
              the shortest possible lead time is greatly enhanced and enabling
              us to service the requirements of our customers from the nearest
              location quickly.
            </p>
          </div>
          <img className="mapImage" src="/assets/images/mapimage.png" />
        </div>
      </Fade>
    </div>
  );
};

export default AboutUs;
